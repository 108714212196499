<template>
    <Page
        :title="$t('payments.create.title')"
        :class="{ backdrop: isTPPWidgetVisible }"
    >
        <Loader
            :loading="isLoading"
            :backdrop="true"
        />

        <BalanceInfo
            :date="currentDate"
            :balance="balance"
            :backdrop="isTPPWidgetVisible"
        />

        <div class="flex justify-between flex-wrap">
            <!-- full amount -->
            <div>
                <div class="flex flex-col">
                    <div class="mb-5">
                        <input
                            id="fullAmount"
                            v-model="paymentType"
                            type="radio"
                            class="radio mr-2"
                            :value="paymentTypes.full"
                            :disabled="isPositiveBalance"
                        />
                        <label
                            for="fullAmount"
                            class="radio-label"
                        >
                            {{ $t('payments.list_columns.full_amount') }}
                        </label>
                    </div>
                    <div
                        v-if="!isPositiveBalance"
                        class="tracking-normal font-medium font-ffdin text-xl"
                    >
                        {{ balanceABS }}
                    </div>
                </div>
            </div>
            <!-- other amount -->
            <div class="flex flex-col">
                <div class="mb-1">
                    <input
                        id="otherAmount"
                        v-model="paymentType"
                        type="radio"
                        class="radio mr-2"
                        :value="paymentTypes.other"
                        @change="onChange"
                    />
                    <label
                        for="otherAmount"
                        class="radio-label"
                    >
                        {{ $t('payments.list_columns.other_amount') }}
                    </label>
                </div>
                <AmountInput
                    ref="otherAmount"
                    v-model="valueAmount.other"
                    prefix="$"
                    :min="0"
                    :disabled="isFullAmount"
                    :amount-class="isTPPWidgetVisible ? 'input-backdrop' : ''"
                />
            </div>
        </div>

        <div class="form-field mt-7 mb-3">
            <label class="text-gray-500 text-xs tracking-wide font-medium">
                {{ $t('payments.list_columns.payment_date') }}
            </label>
            <div class="flex items-center rounded-lg border border-solid border-gray-250 px-4 py-6 mt-2">
                <icon
                    class="w-5 h-5 mb-1"
                    name="calendar"
                />
                <p class="ml-4 text-lg font-ffdin tracking-normal">
                    {{ currentDate }}
                </p>
            </div>
        </div>

        <template v-slot:footer>
            <div class="flex px-6 md:px-4 py-6">
                <button
                    v-if="!isTPPWidgetVisible"
                    type="submit"
                    class="flex-1 btn btn-primary text-pink-light"
                    :title="payNowTooltip"
                    :disabled="isPayNowDisabled"
                    @click="generateTPPAccessToken"
                >
                    {{ $t('payments.pay_now') }}
                </button>
            </div>
        </template>
    </Page>
    <TPPWidgetWrapper
        v-if="isTPPWidgetVisible && !mobileDevice"
        :title="$t('payments.rent_and_services')"
        :styles="{ height: '779px' }"
    />
</template>

<script>
import Page from '@/components/ui/Page';
import BalanceInfo from '@/components/payments/BalanceInfo.vue';
import Icon from '@/components/ui/Icon';
import NotifyMixin from '@/mixins/NotifyMixin.js';
import AuthMixin from '@/mixins/AuthMixin.js';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import AccountingMixin from '@/mixins/AccountingMixin';
import Loader from '@/components/ui/Loader';
import AmountInput from '@/components/ui/AmountInput';
import { paymentTypes } from '@/components/dashboard/constants.js';
import { currentDate } from '@/utils/Date';
import { mask } from '@/utils/Amount';
import { mapGetters } from 'vuex';
import { PAYMENT_METHODS_MAP, PAYMENT_STATUS } from '@/utils/constants/accounting';
import TriplePlayPayMixin from '@/mixins/TriplePlayPayMixin';
import TPPWidgetWrapper from '@/components/payments/TPPWidgetWrapper';
import PaymentsMixin from '@/mixins/PaymentsMixin';

export default {
    components: { TPPWidgetWrapper, Page, Icon, BalanceInfo, AmountInput, Loader },

    mixins: [NotifyMixin, AuthMixin, AccountingQueryManager, AccountingMixin, TriplePlayPayMixin, PaymentsMixin],

    data() {
        return {
            isLoading: false,
            currentDate,
            paymentTypes,
            paymentType: paymentTypes.full,
            occupancyRelation: null,
            account: null,
            valueAmount: {
                full: 0,
                other: 0,
            },
        };
    },

    computed: {
        ...mapGetters({
            getApplication: 'application/getApplication',
            chargeTotals: 'accounting/chargeTotals',
            currentProfile: 'auth/currentProfile',
            isTPPWidgetVisible: 'accounting/isTPPWidgetVisible',
        }),

        balance() {
            return mask(this.chargeTotals?.balance, { prefix: '$' });
        },

        balanceABS() {
            return mask(Math.abs(this.chargeTotals?.balance), { prefix: '$' });
        },

        isPositiveBalance() {
            return this.chargeTotals?.balance < 0;
        },

        isFullAmount() {
            return this.paymentType === this.paymentTypes.full;
        },

        isOtherAmount() {
            return this.paymentType === this.paymentTypes.other;
        },

        isPayNowDisabled() {
            return (
                (this.isOtherAmount && this.valueAmount.other <= 0) || this.isNsfLimitReached || this.availablePaymentMethods.length === 0
            );
        },

        payNowTooltip() {
            return this.isNsfLimitReached ? this.$t('payments.pay_now_is_not_allowed_description') : '';
        },

        customTPPPaymentConfig() {
            const amount = Math.abs(this.valueAmount[this.paymentType]);
            return {
                paymentType: 'charge',
                amount,
                paymentOptions: this.availablePaymentMethods,
            };
        },
    },

    async created() {
        this.valueAmount.full = this.chargeTotals?.balance;
        if (this.isPositiveBalance) {
            this.paymentType = paymentTypes.other;
        }
        await Promise.all([this.getOccupancy(), this.getAvailablePaymentMethods()]);
    },

    methods: {
        getPaymentDate() {
            return new Date().toISOString();
        },

        getOccupancy() {
            this.isLoading = true;
            this.$leasingServiceDataProvider
                .getOne('occupancy', {
                    occupancy_id: this.currentProfile.occupancyId,
                })
                .then(res => {
                    this.occupancyRelation = res.occupancy_relation.find(({ party }) => party.contact_id === this.currentProfile.contactId);
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => (this.isLoading = false));
        },

        generateAccountingPaymentRecord({ transactionId, amount, status, reason, paymentMethodType }) {
            return {
                transactionId,
                status,
                reason,
                paymentMethodType,
                accountId: this.currentProfile.occupancyId,
                payerId: this.occupancyRelation?.party?.party_id,
                paymentDate: this.getPaymentDate(),
                accrualAmount: Number(amount),
                paymentAmount: Number(amount),
                paymentCurrency: 'USD',
                unitName: this.currentProfile.locationName,
                payerName: this.occupancyRelation?.party?.full_name,
            };
        },

        async processPaymentDataBasedOnStatus(data) {
            const isPaymentSuccessful = data?.action === 'success';
            const formattedReason = Array.isArray(data?.payload?.details?.message)
                ? data?.payload?.details?.message?.map(error => error.description)
                : data?.payload?.details?.message;
            const formattedData = this.generateAccountingPaymentRecord({
                amount: data?.payload?.amount,
                transactionId: data?.payload?.transaction_id,
                status: isPaymentSuccessful ? PAYMENT_STATUS.authorized : PAYMENT_STATUS.declined,
                reason: isPaymentSuccessful ? null : formattedReason,
                paymentMethodType: PAYMENT_METHODS_MAP[data?.payload?.method],
            });
            await this.recordPaymentForAccounting(formattedData);
        },

        async recordPaymentForAccounting(data) {
            try {
                await this.queryHandler('createPayment', 'resident', {
                    accountId: this.currentProfile.occupancyId,
                    customerId: this.currentProfile.customerId,
                    communityId: this.currentProfile.communityId,
                    data: { ...data },
                });

                if (data.status === PAYMENT_STATUS.authorized) {
                    this.notifySuccess(this.$t('payments.messages.payment_successful'));
                }
                if (data.status === PAYMENT_STATUS.declined) {
                    this.notifyError(this.$t('payments.messages.transaction_declined'));
                }

                await this.getTotals();
                await this.$router.push({
                    name: 'dashboard.index',
                });
            } catch (error) {
                this.notifyError(error.message);
            }
        },

        onChange() {
            this.$refs.otherAmount.$refs.field.focus();
        },
    },
};
</script>
