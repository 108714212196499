import NotifyMixin from '@/mixins/NotifyMixin';
import AccountingQueryManager from '@/api/accounting/accountingQueryManager';
import { mapGetters } from 'vuex';

export default {
    mixins: [NotifyMixin, AccountingQueryManager],

    data() {
        return {
            totals: [],
            selectedPeriod: null,
            loadMore: false,
            stopRequest: false,
        };
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
            oneYearPeriods: 'accounting/oneYearPeriods',
        }),
    },

    watch: {
        currentProfile: {
            async handler(newVal, oldVal) {
                if (newVal && newVal !== oldVal && this.selectedPeriod) {
                    await this.getTotals();
                    if (this.availableRoutes) {
                        this.updateAvailableRoutes();
                    }
                }
            },
        },
    },

    methods: {
        async handlePeriodSelection(period) {
            this.selectedPeriod = period;
            if (this.currentProfile) {
                this.stopRequest = false;
                this.totals = [];
                await this.getTotals();
            }
        },

        async getTotals() {
            if (this.currentProfile?.occupancyId) {
                this.isLoading = true;
                try {
                    const totals = await this.queryHandler('getTotals', 'resident', {
                        accountId: this.currentProfile.occupancyId,
                        startDate: this.selectedPeriod?.startDate,
                        endDate: this.selectedPeriod?.endDate,
                    });
                    const reversedTotals = totals?.reverse();
                    reversedTotals.forEach(total => {
                        this.totals.push(total);
                    });
                    const seenPeriods = new Set();
                    this.totals = this.totals.filter(total => {
                        const isDuplicate = seenPeriods.has(total.period);
                        seenPeriods.add(total.period);
                        return !isDuplicate;
                    });
                    if (!totals.length) {
                        this.$router.replace({ name: 'application_service.index' });
                    }
                } catch (error) {
                    this.notifyError(error.message);
                } finally {
                    this.loading = false;
                    this.resetValueToDefault = false;
                }
            }
        },

        handleScroll() {
            if (this.oneYearPeriods.length > 0 && this.selectedPeriod?.key === 'all' && !this.stopRequest) {
                this.oneYearPeriods.forEach(period => {
                    this.selectedPeriod.startDate = period.startDate;
                    this.selectedPeriod.endDate = period.endDate;
                    this.loadMore = true;
                    this.getTotals();
                    this.stopRequest = true;
                });
            }
        },
    },
};
